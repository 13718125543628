import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')




export const resolvePerPageOptions = () => {
  return [10, 25, 50, 100]
}

export const resolveLightStatusVariant = ( status ) => {
  if (status == '1') return 'light-success'
  return 'light-secondary'
}

export const resolveStatusVariant = ( status ) => {
  if (status == '1') return 'success'
  return 'secondary'
}

export const fetchTypeOptions = () => {
  // 0 admin 1 talento humano 2 jefatura 3 seguridad 9 root
  return [
    { text: 'Talento humano', value: 1 },
    { text: 'Jefatura', value: 2 },
    { text: 'Seguridad', value: 3 },
  ]
}


export const fetchStatusOptions = () => {
  // 0 inactive 1 active
  return [
    { text: 'Activo', value: 1 },
    { text: 'Inactivo', value: 0 },
  ]
}

export const resolveStatusName = ( status ) => {
  if (status == '1') return 'activo'
  return 'inactivo'
}

export const resolveTypeName = ( type ) => {
  const types = {
    '0': 'admin',
    '1': 'talento humano',
    '2': 'jefatura',
    '3': 'analista de seguridad',
    '9': 'root'
  }
  return types[ type ] ?? "no definido"
}

export const resolveTypeIcon = type => {
  const types = {
    '0': 'StarIcon',
    '1': 'UserIcon',
    '2': 'AwardIcon',
    '3': 'KeyIcon',
    '9': 'UsersIcon'
  }
  return types[ type ] ?? "CircleIcon"
}

export const resolveTypeVariant = type => {
  const types = {
    '0': 'success',
    '1': 'warning',
    '2': 'info',
    '3': 'danger',
    '9': 'danger'
  }
  return types[ type ] ?? "primary"
}


/*
FORMULARIOS / SOLICITUDES
 */



export const resolveFormatoFecha = ( date ) => {
  return moment(date).format('ddd  DD-MMM-YYYY')
}

export const resolveFormatoFechaHora = ( date ) => {
  return moment(date).format('ddd  DD-MMM-YYYY hh:mm')
}


export const fetchFormStatusOptions = () => {
  // 0 inactive 1 active
  return [
    { text: 'Activa', value: 1 },
    { text: 'Corrección', value: 2 },
    { text: 'Proceso', value: 3 },
    { text: 'Finalizada', value: 7 },
    { text: 'Cancelada', value: 8 },
    { text: 'Anulada', value: 9 },
  ]
}
export const fetchresolveFormStatusName = () => {
  // 0 inactive 1 active
  return [
    { text: 'Pendiente', value: 1 },
    { text: 'Corrección', value: 2 },
    { text: 'Proceso', value: 3 },
    { text: 'Realizado', value: 7 },
    { text: 'Cancelada', value: 8 },
    { text: 'Anulada', value: 9 },
  ]
}
export const resolveFormStatusName = ( status ) => {
  const nameStatus = {
    '1': 'activa',
    '2': 'corrección',
    '3': 'proceso',
    '7': 'finalizada',
    '8': 'cancelada',
    '9': 'anulada'
  }

  if ( status.latestStatus )
    return nameStatus[ status.latestStatus ] ?? "ninguno"

  if (status.status == '1') return 'creado'
  return 'borrador'
}

export const resolveTaskStatusName = ( status ) => {
  const nameStatus = {
    '1': 'pendiente',
    '2': 'corrección',
    '3': 'proceso',
    '7': 'realizado',
    '8': 'cancelada',
    '9': 'anulada'
  }

  if ( status.latestStatus )
    return nameStatus[ status.latestStatus ] ?? "ninguno"

  if (status.status == '1') return 'creado'
  return 'borrador'

}


export const resolveLightFormStatusVariant = ( status ) => {

  const colorStatus = {
    '1': 'light-primary',
    '2': 'light-warning',
    '3': 'light-info',
    '7': 'light-success',
    '8': 'light-dark',
    '9': 'light-danger'
  }

  if ( status.latestStatus )
    return colorStatus[ status.latestStatus ] ?? "primary"

  if (status.status == '1') return 'light-success'

  return 'light-secondary'
}

export const resolveFormStatusVariant = ( status ) => {

  const colorStatus = {
    '1': 'primary',
    '2': 'warning',
    '3': 'info',
    '7': 'success',
    '8': 'dark',
    '9': 'danger'
  }

  if ( status.latestStatus )
    return colorStatus[ status.latestStatus ] ?? "primary"

  if (status.status == '1') return 'success'

  return 'secondary'
}


/*
TAREAS
 */

export const resolveToDoVariant = ( task ) => {

  const colorToDo = {
    '1': 'success',
    '2': 'warning',
    '3': 'danger',
    '4': 'info'
  }
  return colorToDo[ task ] ?? "primary"
}

export const resolveToDoName = ( task ) => {
  const nameToDo = {
    '1': 'crear',
    '2': 'agregar',
    '3': 'eliminar',
    '4': 'cambio'
  }

  return nameToDo[ task ] ?? "ninguno"

}


